<template>
	<div class="outer">
		<div class="wrapper">
			<div class="values">
				<span ref="range1" v-if="this.low || this.low === 0">
					{{ formatFunction(formattedValue.low) }}
				</span>
				<span v-if="this.low || this.low === 0"> &dash; </span>
				<span ref="range2" v-if="formattedValue.high">
					{{ formatFunction(formattedValue.high) }}
				</span>
				<span ref="range2" v-else>
					{{ formatFunction(formattedValue) }}
				</span>
			</div>
			<div class="container">
				<div class="slider-track" ref="sliderTrack"></div>
				<input v-if="this.low || this.low === 0" type="range" :min="minValueLower" :max="maxValueUpper" v-model="low" ref="slider1" :input="slideOne()" :step="incrementValue" class="min">
				<input type="range" :min="minValueLower" :max="maxValueUpper" v-model="high" ref="slider2" :step="incrementValue" :input="slideTwo()" class="max">
			</div>
		</div>
	</div>
</template>

<script>


	export default {
		// This component takes a v-model in either of the following formats:
			// values: { low: 40, high: 60 }
			// values: 60
		// If low & high are provided, the slider will be a range
		// If not, just a single value
		props: {
			value: {

			},
			minGap: {
				default: 0
			},
			minValue: {
				default: 0
			},
			maxValue: {
				default: 100
			},
			incrementValue: {
				default: 1
			},

			formatFunction: {
				type: Function,
				default: function (x) {
					return x;
				},
			}
		},
		data() {
			return {
				low: this.value.low || this.value.low === 0 ? this.value.low : false,
				high: this.value.high ? this.value.high : this.value,

				maxValueUpper: this.maxValue, // Used to enable selection of max value (otherwise it's impossible to select the max value)
				minValueLower: this.minValue, // Used to enable selection of min value (otherwise it's impossible to select the min value)
			}
		},
		computed: {
			formattedValue() {
				// Convert selected max/min values BACK to the actual min & max values
				let returnLow = this.low < this.minValue ? this.minValue : this.low; 
				let returnHigh = this.high > this.maxValue ? this.maxValue : this.high; 
				if(this.low || this.low === 0) {
					return {
						low: returnLow,
						high: returnHigh,
					}
				}
				return returnHigh;
			}
		},
		methods: {
			slideOne() {
				if(parseInt(this.high) - parseInt(this.low) <= this.minGap){
					this.low = parseInt(this.high) - this.minGap;
				}
				this.fillColor();
				this.$emit('input', this.formattedValue);
				this.$emit('change');
			},
			slideTwo(){
				if(parseInt(this.high) - parseInt(this.low) <= this.minGap){
					this.high = parseInt(this.low) + this.minGap;
				}
				this.fillColor();
				this.$emit('input', this.formattedValue);
				this.$emit('change');
			},
			fillColor(){
				if(this.$refs.sliderTrack != undefined) {
					let offset = this.minValueLower; // Offset needed if min value is not 0
					let percent1 = ((this.low - offset) / (this.maxValueUpper - offset)) * 100;
					let percent2 = ((this.high - offset) / (this.maxValueUpper - offset)) * 100;

					this.$refs.sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #118AB2 ${percent1}% , #118AB2 ${percent2}%, #dadae5 ${percent2}%)`;
				}
			}
		},
		mounted () {
			// Round max value to nearest increment value - so that the user can select the max value
			this.maxValueUpper = Math.ceil(this.maxValue / this.incrementValue) * this.incrementValue;
			this.minValueLower = Math.floor(this.minValue / this.incrementValue) * this.incrementValue;

			// Set the low and high values, allowing for both formats
			if(typeof this.value === 'object' && this.value !== null) {
				this.low = this.value.low || this.minValueLower;
				this.high = this.value.high || this.maxValueUpper;
			} else {
				this.low = false
				this.high = this.value || this.maxValueUpper;
			}
			
			if(this.low) { // Never call slideOne if there is no lower bound set. This will cause the slider to become a range slider
				if(this.low < this.minValueLower + this.incrementValue) { // If increment value doesn't allow the user to select the min value, round to the minValue
					this.low = this.minValueLower;
				}
				this.slideOne();
			}
			if(this.high > this.maxValueUpper - this.incrementValue) { // If increment value doesn't allow the user to select the max value, round to the maxValue
				this.high = this.maxValueUpper;
			}
			this.slideTwo();
		},
	}
</script>

<style scoped>

.outer{
   width: 270px;
}
.wrapper{
    /* position: relative;
    width: 100px;
    background-color: #ffffff;
    padding: 50px 40px 20px 40px;
    border-radius: 10px; */
}
.container{
    position: relative;
	height: 22px;
}
input[type="range"]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
}
.slider-track{
    width: 98%;
    height: 7px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
	left: 1%;
}
input[type="range"]::-webkit-slider-runnable-track{
    -webkit-appearance: none;
    height: 7px;
	border-radius: 7px;
}
input[type="range"]::-moz-range-track{
    -moz-appearance: none;
    height: 7px;
	border-radius: 7px;
}
input[type="range"]::-ms-track{
    appearance: none;
    height: 7px;
	border-radius: 7px;
}
input[type="range"]::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 19px;
    width: 19px;
    background-color: #118AB2;
    cursor: pointer;
    margin-top: -7px;
    pointer-events: auto;
    border-radius: 50%;
	outline:none;
	border-color: transparent;
}
input[type="range"]::-moz-range-thumb{
    -webkit-appearance: none;
    height: 19px;
    width: 19px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #118AB2;
    pointer-events: auto;
	outline:none;
	border-color: transparent;
}
input[type="range"]::-ms-thumb{
    appearance: none;
    height: px;
    width: 19px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #118AB2;
    pointer-events: auto;
}
input[type="range"]:active::-webkit-slider-thumb{
    background-color: #ffffff;
    border: 3px solid #118AB2;
}
.values {
	height: 15px;
	margin-bottom: 5px;
	color: black;
	font-size: 12px;
}
</style>