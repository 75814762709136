<template>
	<div class="outer-wrapper filters"> 

		<div id="top-nav"> 
			<router-link :to="{ name: 'Flights Filters' }">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
		</div>
	
		<div class="inner-wrapper main"> <!-- Inner wrapper handles global padding -->

			<div class="heading-section">
				<img class="icon" width="20px" src="@/assets/filters/times.png" />
				<h1 class="heading6">Departure time from {{ searchValues.startLocation.code }}</h1>
			</div>

			<RangeSlider 
				v-if="searchFilters.outboundDepartureRange"
				
				class="slider"
				minValue="0"
				maxValue="86400"
				:incrementValue="15 * 60"
				:formatFunction="formatSecondsAsTime"
				v-model="searchFilters.outboundDepartureRange"
			/>

			<div v-if="searchValues.tripType == 'return'" class="return">
				<div class="heading-section">
					<h1 class="heading6">Departure time from {{ searchValues.endLocation.code }}</h1>
				</div>
				<RangeSlider 
					v-if="searchFilters.returnDepartureRange"
					
					class="slider"
					minValue="0"
					maxValue="86400"
					:incrementValue="15 * 60"
					:formatFunction="formatSecondsAsTime"
					v-model="searchFilters.returnDepartureRange"
				/>
			</div>
		</div>
			
		<div class="bottom inner-wrapper">
			<router-link :to="{ name: 'Flights Filters' }">
				<button class="button">Continue</button>
			</router-link>
		</div>
	</div>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import helpers from '@/helpers/helpers.js';
import RangeSlider from '@/components/RangeSlider.vue';
import router from '@/router';

export default {
    data () {
        return {
        }
	},
	components: {
		RangeSlider,
	},
   computed: {
		...mapState({
			searchFilters: state => state.flightsSearch.searchFilters,
			searchResults: state => state.flightsSearch.searchResults,
			searchValues: state => state.flightsSearch.searchValues,
		}),
		...mapGetters({
			filterOptions: 'flightsSearch/getFilterOptions',
		})
	},
    methods: {
		formatSecondsAsTime: (seconds) => helpers.formatSecondsAsTime(seconds),
	},
	created () {
		if(this.searchResults.length == 0) { // Probably a page refresh - redirect to results page (with query params)
			router.push({
				name: 'Flights Results',
			})
		}
	},
	
};
</script>


<style scoped>
	.heading-section {
		text-align: left;
	}

	.slider {
		margin-top: 20px;
	}

	.return {
		margin-top: 50px;
	}
	
</style>